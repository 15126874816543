import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import useErrors from "src/hooks/useErrors";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import fetchUser from "src/utils/fetchUser";
import { HashLink } from "react-router-hash-link";
import useAuth from "src/hooks/useAuth";
import axiosInstance from "src/utils/axios";
import { Link as RouterLink } from "react-router-dom";
import useLocales from "src/hooks/useLocales";

const useChangeNotificationStatus = (fetchData) => {
  const { isAdmin } = useAuth();
  const handleError = useErrors();
  const url = isAdmin
    ? "api/admin/notifications/"
    : "api/user/view-notifications/";
  const changeStatus = async (id) => {
    const reqData = new FormData();
    reqData.append("_method", "PUT");
    try {
      const { data, status } = await axiosInstance.post(`${url}${id}`, reqData);

      if (status === 200) {
        fetchData();
      }
    } catch (err) {
      handleError(err);
    }
  };

  return changeStatus;
};

const useChangePublicNotification = (fetchData) => {
  const handleError = useErrors();
  const handlePublicNotification = async (id) => {
    const reqData = new FormData();
    reqData.append("_method", "PUT");
    try {
      const { data, status } = await fetchUser.post(
        `view-notifications/${id}`,
        reqData
      );

      if (status === 200) {
        fetchData();
      }
    } catch (err) {
      handleError(err);
    }
  };
  return handlePublicNotification;
};
const useNotification = () => {
  const { isAdmin } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [publicNotifications, setPublicNotifications] = useState([]);
  const handleError = useErrors();

  const url = isAdmin ? "/api/admin/notifications" : "/api/user/notifications";
  const fetchData = async () => {
    try {
      const { data, status } = await axiosInstance(url);
      if (status === 200) {
        setNotifications(data.data.mail_notifications);
        setPublicNotifications(isAdmin ? [] : data?.data.public_notifications);
      }
    } catch (err) {
      handleError(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return { notifications, publicNotifications, fetchData };
};
const useUpdateView = (fetchData) => {
  const handleError = useErrors();
  const handleViewNotification = async (id, isMail = false) => {
    const reqData = new FormData();
    reqData.append("_method", "PUT");
    reqData.append("key", isMail ? 1 : 0);
    try {
      const { data, status } = await fetchUser.post(
        `view-notifications/${id}`,
        reqData
      );

      if (status === 200) {
        fetchData();
      }
    } catch (err) {
      handleError(err);
    }
  };
  return handleViewNotification;
};
export default function NotificationsPopover() {
  const { isAdmin } = useAuth();
  const { translate } = useLocales();
  const { notifications, publicNotifications, fetchData } = useNotification();
  const unReadNotifications = notifications.filter(
    (item) => item.is_view === 0
  ).length;
  const unReadPublicNotifications = publicNotifications.filter(
    (item) => item.is_view_user_id === null
  ).length;

  const isNotificationCount = notifications.length + publicNotifications.length;

  const totalUnread = unReadPublicNotifications + unReadNotifications;

  // admin side count view
  const adminNotificationCountOne = notifications.length;
  const adminNotificationCountTwo = publicNotifications.length;
  const adminTotalCount = adminNotificationCountOne + adminNotificationCountTwo;

  const UnReadPublicNotification = publicNotifications.length;
  const changeStatus = useChangeNotificationStatus(fetchData);
  const handlePublicNotification = useChangePublicNotification(fetchData);
  const handleViewNotification = useUpdateView(fetchData);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {};

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge
          badgeContent={isAdmin ? adminTotalCount : isNotificationCount}
          color="error"
        >
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {isNotificationCount} unread messages
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Scrollbar sx={{ maxHeight: { xs: 340, sm: 400 } }}>
          {unReadNotifications > 0 && (
            <Box sx={{ ml: 1 }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: 700, color: "#00c59e", p: "10px" }}
              >
                Email Notifications
              </Typography>
            </Box>
          )}

          <Divider sx={{ borderStyle: "dashed" }} />

          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                {...notification}
                changeStatus={handleViewNotification}
                handleAdminNotification={changeStatus}
              />
            ))}
          </List>

          {UnReadPublicNotification > 0 && (
            <Box sx={{ ml: 1 }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: 700, color: "#00c59e", p: "10px" }}
              >
                Public Notifications
              </Typography>
            </Box>
          )}

          <Divider sx={{ borderStyle: "dashed" }} />

          <List disablePadding>
            {publicNotifications.map((notification) => (
              <PublicNotificationItem
                key={notification.id}
                {...notification}
                changeStatus={handleViewNotification}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />
        {!isAdmin && totalUnread > 0 && (
          <Box sx={{ p: 1, textAlign: "right" }}>
            <Button
              component={RouterLink}
              to={PATH_USER.notifications}
              onClick={handleClose}
              size="small"
              color="primary"
              endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
            >
              {translate("userDashboard.viewMore")}
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({
  is_read,
  message,
  id,
  email_id,
  is_view,
  changeStatus,
  handleAdminNotification,
}) {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const linkTo = (email_id) => {
    const baseUrl = isAdmin
      ? `${PATH_DASHBOARD.mail.root}/inbox/${email_id}`
      : `${PATH_USER.mail.root}/inbox/${email_id}`;

    return baseUrl;
  };
  const isMail = true;
  return (
    <ListItemButton
      onClick={() => {
        isAdmin ? handleAdminNotification(id) : changeStatus(id, isMail);
        navigate(linkTo(email_id));
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!is_read && {
          bgcolor: "action.selected",
        }),
        backgroundColor: !isAdmin
          ? is_view === 0
            ? "#364350"
            : "#161C24"
          : null,
      }}
    >
      {is_view === 0 ? (
        <ListItemText
          sx={{ fontWeight: "bold", fontWeight: 800 }}
          primary={message}
        />
      ) : (
        <ListItemText
          sx={{
            fontWeight: "bold",
            opacity: !isAdmin ? 0.4 : null,
            fontWeight: 300,
          }}
          primary={message}
        />
      )}
    </ListItemButton>
  );
}

function PublicNotificationItem({
  is_read,
  message,
  id,
  type_id,
  type,
  is_view_user_id,
  is_view,
  changeStatus,
  handleClose,
}) {
  const navigate = useNavigate();
  const isMail = false;
  const LinkTo = (type, id) => {
    if (type === "blog") {
      navigate(PATH_USER.blogs.view(id));
    }

    if (type === "course") {
      navigate(PATH_USER.course.chapterView(id));
    }
    // navigate();
  };
  return (
    <ListItemButton
      onClick={() => {
        changeStatus(id, isMail);
        type === "event" ? navigate("/user/events") : LinkTo(type, type_id);
        handleClose();
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!is_read && {
          bgcolor: "action.selected",
        }),
        backgroundColor: is_view_user_id === null ? "#364350" : "#161C24",
      }}
    >
      {is_view_user_id === null ? (
        <ListItemText
          sx={{ fontWeight: "bold", fontWeight: 800 }}
          primary={message}
          onClick={handleClose}
        />
      ) : (
        <ListItemText
          sx={{
            fontWeight: "bold",
            opacity: !is_view === 0 ? 0.4 : null,
            fontWeight: 300,
          }}
          primary={message}
          onClick={handleClose}
        />
      )}
    </ListItemButton>
  );
}
