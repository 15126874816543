import React from "react";
import { CURRENCY } from "src/config";

// currency obj ref site : https://www.eurochange.co.uk/travel/tips/world-currency-abbreviations-symbols-and-codes-travel-money

const obj = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  CNY: "¥",
  EGP: "£",
  INR: "₹",
  JPY: "¥",
  USDT: "USDT",
};

export const Currency = ({ children }) => {
  const symbol = obj[CURRENCY];

  return (
    <>
      {children}
      <span translate="no">{symbol}</span>
    </>
  );
};

export const withCurrency = (amount) => {
  const value = Boolean(amount) ? amount : 0;
  const symbol = obj[CURRENCY];
  return `${value}${symbol}`;
};
