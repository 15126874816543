import { capitalCase } from "change-case";
import React from "react";
import { RHFSelect } from "src/components/hook-form";
import Map from "src/components/map";
import useLocales from "src/hooks/useLocales";
import { isBinary } from "src/utils/isBinary";

const AmountType = ({ isWallet }) => {
  const { translate } = useLocales();

  const options =
    isWallet === "ewallet"
      ? [
          { value: "", label: "userFinancial.depositWallet.all" },
          { value: "daily_bonus", label: "userFinancial.eWallet.daily_bonus" },
          { value: "fund_transfer", label: "userFinancial.depositWallet.fundTransfer" },
          { value: "binary_bonus", label: "userFinancial.depositWallet.binary_bonus" },
          { value: "direct_bonus", label: "userFinancial.depositWallet.direct_bonus" },
          { value: "ewallet", label: "userFinancial.depositWallet.e_wallet" },
          { value: "credited_by_admin", label: "userFinancial.depositWallet.creditedByAdmin" },
          { value: "deducted_by_admin", label: "userFinancial.depositWallet.deductedByAdmin" },
          { value: "indirect_bonus", label: "userFinancial.depositWallet.indirect_bonus" },
          { value: "volume_bonus", label: "userFinancial.depositWallet.volume_bonus" },
          { value: "invest_amount", label: "userFinancial.depositWallet.invest_amount" },
        ]
      : [
          { value: "", label: "userFinancial.depositWallet.all" },
          { value: "fund_transfer", label: "userFinancial.depositWallet.fundTransfer" },
          { value: "product_purchased", label: "userFinancial.depositWallet.productPurchased" },
          { value: "credited_by_admin", label: "userFinancial.depositWallet.creditedByAdmin" },
          { value: "deducted_by_admin", label: "userFinancial.depositWallet.deductedByAdmin" },
          { value: "invest_amount", label: "userFinancial.depositWallet.invest_amount" },
          { value: "deposit_wallet", label: "userFinancial.depositWallet.deposit_wallet" },
          
        ];

  return (
    <RHFSelect
      name="payment_type"
      label={translate("userFinancial.eWallet.amountType")}
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
    >
      <Map
        list={isBinary() ? [...options] : options}
        render={({ value, label }) => (
          <Option key={value} value={value} label={label} isWallet={isWallet} />
        )}
      />
    </RHFSelect>
  );
};

const Option = ({ value, label }) => {
  const { translate } = useLocales();

  return <option value={value}>{capitalCase(translate(label))}</option>;
};

export default AmountType;
